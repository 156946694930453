// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import EventOccurenceResourceViewModel from '../../../../viewModel/resource/EventOccurenceResourceViewModel';
import WidgetEventResourceViewModel from '../../../../viewModel/resource/WidgetEventResourceViewModel';
import WidgetEventResourceDTO from '../../dto/resource/WidgetEventResourceDTO';

export default abstract class WidgetEventResourceModel extends BaseModel<WidgetEventResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  */
  get certificate() { return this.dto.certificate; }
  set certificate(value) { this.dto.certificate = value; }
  /**
  */
  get place() { return this.dto.place; }
  set place(value) { this.dto.place = value; }
  /**
  */
  get comment() { return this.dto.comment; }
  set comment(value) { this.dto.comment = value; }
  /**
  */
  get contactEmail() { return this.dto.contactEmail; }
  set contactEmail(value) { this.dto.contactEmail = value; }
  /**
  */
  get contactPerson() { return this.dto.contactPerson; }
  set contactPerson(value) { this.dto.contactPerson = value; }
  /**
  */
  get subtitle() { return this.dto.subtitle; }
  set subtitle(value) { this.dto.subtitle = value; }
  /**
  */
  get costs() { return this.dto.costs; }
  set costs(value) { this.dto.costs = value; }
  /**
  */
  get description() { return this.dto.description; }
  set description(value) { this.dto.description = value; }
  /**
  */
  get documentation() { return this.dto.documentation; }
  set documentation(value) { this.dto.documentation = value; }
  /**
  */
  get preparation() { return this.dto.preparation; }
  set preparation(value) { this.dto.preparation = value; }
  /**
  */
  get registrationDeadline() { return this.dto.registrationDeadline; }
  set registrationDeadline(value) { this.dto.registrationDeadline = value; }
  /**
  */
  get cancellation() { return this.dto.cancellation; }
  set cancellation(value) { this.dto.cancellation = value; }
  /**
  */
  get workingHours() { return this.dto.workingHours; }
  set workingHours(value) { this.dto.workingHours = value; }
  /**
  */
  get catering() { return this.dto.catering; }
  set catering(value) { this.dto.catering = value; }
  /**
  */
  get travelExpenses() { return this.dto.travelExpenses; }
  set travelExpenses(value) { this.dto.travelExpenses = value; }
  /**
  */
  get duration() { return this.dto.duration; }
  set duration(value) { this.dto.duration = value; }
  /**
  */
  get dates() { return this.dto.dates; }
  set dates(value) { this.dto.dates = value; }
  /**
  * @type {int32}
  */
  get durationDays() { return this.dto.durationDays; }
  set durationDays(value) { this.dto.durationDays = value; }
  /**
  * @type {int32}
  */
  get durationHourTarget() { return this.dto.durationHourTarget; }
  set durationHourTarget(value) { this.dto.durationHourTarget = value; }
  /**
  * @type {int64}
  */
  get eventNr() { return this.dto.eventNr; }
  set eventNr(value) { this.dto.eventNr = value; }
  /**
  * @type {date-time}
  */
  get intendedDate() { return this.dto.intendedDate; }
  set intendedDate(value) { this.dto.intendedDate = value; }
  /**
  * @type {date-time}
  */
  get openDate() { return this.dto.openDate; }
  set openDate(value) { this.dto.openDate = value; }
  /**
  */
  get leader() { return this.dto.leader; }
  set leader(value) { this.dto.leader = value; }
  /**
  */
  get meetingPoint() { return this.dto.meetingPoint; }
  set meetingPoint(value) { this.dto.meetingPoint = value; }
  /**
  */
  get notes() { return this.dto.notes; }
  set notes(value) { this.dto.notes = value; }
  /**
  */
  get administration() { return this.dto.administration; }
  set administration(value) { this.dto.administration = value; }
  /**
  */
  get organisation() { return this.dto.organisation; }
  set organisation(value) { this.dto.organisation = value; }
  /**
  */
  get remarks() { return this.dto.remarks; }
  set remarks(value) { this.dto.remarks = value; }
  /**
  * @type {double}
  */
  get costsPerParticipant() { return this.dto.costsPerParticipant; }
  set costsPerParticipant(value) { this.dto.costsPerParticipant = value; }
  /**
  * @type {double}
  */
  get participationCosts() { return this.dto.participationCosts; }
  set participationCosts(value) { this.dto.participationCosts = value; }
  /**
  */
  get eventManager() { return this.dto.eventManager; }
  set eventManager(value) { this.dto.eventManager = value; }
  /**
  */
  get previousKnowledge() { return this.dto.previousKnowledge; }
  set previousKnowledge(value) { this.dto.previousKnowledge = value; }
  /**
  */
  get requirements() { return this.dto.requirements; }
  set requirements(value) { this.dto.requirements = value; }
  /**
  */
  get schedule() { return this.dto.schedule; }
  set schedule(value) { this.dto.schedule = value; }
  /**
  */
  get targetAudience() { return this.dto.targetAudience; }
  set targetAudience(value) { this.dto.targetAudience = value; }
  /**
  */
  get eventGoals() { return this.dto.eventGoals; }
  set eventGoals(value) { this.dto.eventGoals = value; }
  /**
  */
  get teachingAid() { return this.dto.teachingAid; }
  set teachingAid(value) { this.dto.teachingAid = value; }
  /**
  */
  get teachingMethod() { return this.dto.teachingMethod; }
  set teachingMethod(value) { this.dto.teachingMethod = value; }
  /**
  */
  get eventTeachingMethod() { return this.dto.eventTeachingMethod; }
  set eventTeachingMethod(value) { this.dto.eventTeachingMethod = value; }
  /**
  */
  get times() { return this.dto.times; }
  set times(value) { this.dto.times = value; }
  /**
  */
  get name() { return this.dto.name; }
  set name(value) { this.dto.name = value; }
  /**
  */
  get abbreviation() { return this.dto.abbreviation; }
  set abbreviation(value) { this.dto.abbreviation = value; }
  /**
  */
  get niveaus() { return this.dto.niveaus; }
  set niveaus(value) { this.dto.niveaus = value; }
  /**
  */
  get categories() { return this.dto.categories; }
  set categories(value) { this.dto.categories = value; }
  /**
  * @type {date-time}
  */
  get startDate() { return this.dto.startDate; }
  set startDate(value) { this.dto.startDate = value; }
  /**
  * @type {date-time}
  */
  get endDate() { return this.dto.endDate; }
  set endDate(value) { this.dto.endDate = value; }
  /**
  */
  get imageUrl() { return this.dto.imageUrl; }
  set imageUrl(value) { this.dto.imageUrl = value; }
  /**
  */
  get imageThumbUrl() { return this.dto.imageThumbUrl; }
  set imageThumbUrl(value) { this.dto.imageThumbUrl = value; }
  /**
  * @type {double}
  */
  get price() { return this.dto.price; }
  set price(value) { this.dto.price = value; }
  /**
  */
  get registrationStatus() { return this.dto.registrationStatus; }
  set registrationStatus(value) { this.dto.registrationStatus = value; }
  /**
  */
  get registrationIndicator() { return this.dto.registrationIndicator; }
  set registrationIndicator(value) { this.dto.registrationIndicator = value; }
  /**
  */
  get isOnlineRegistrationActive() { return this.dto.isOnlineRegistrationActive; }
  set isOnlineRegistrationActive(value) { this.dto.isOnlineRegistrationActive = value; }
  /**
  */
  get detailUrl() { return this.dto.detailUrl; }
  set detailUrl(value) { this.dto.detailUrl = value; }
  /**
  * @type {int64}
  */
  get eventType() { return this.dto.eventType; }
  set eventType(value) { this.dto.eventType = value; }
  /**
  */
  get registrationFormUrl() { return this.dto.registrationFormUrl; }
  set registrationFormUrl(value) { this.dto.registrationFormUrl = value; }
  /**
  */
  get eventInformationDocumentUrl() { return this.dto.eventInformationDocumentUrl; }
  set eventInformationDocumentUrl(value) { this.dto.eventInformationDocumentUrl = value; }
  /**
  */
  get eventDocumentsUrl() { return this.dto.eventDocumentsUrl; }
  set eventDocumentsUrl(value) { this.dto.eventDocumentsUrl = value; }
  /**
  */
  get locationMapUrl() { return this.dto.locationMapUrl; }
  set locationMapUrl(value) { this.dto.locationMapUrl = value; }
  /**
  */
  get occurences() { return this.getArrayModels(() => this.dto.occurences, EventOccurenceResourceViewModel)!; }
  set occurences(value) { this.setArrayModels(() => this.dto.occurences, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    this.dto.certificate = qs.certificate;
    this.dto.place = qs.place;
    this.dto.comment = qs.comment;
    this.dto.contactEmail = qs.contactemail;
    this.dto.contactPerson = qs.contactperson;
    this.dto.subtitle = qs.subtitle;
    this.dto.costs = qs.costs;
    this.dto.description = qs.description;
    this.dto.documentation = qs.documentation;
    this.dto.preparation = qs.preparation;
    this.dto.registrationDeadline = qs.registrationdeadline;
    this.dto.cancellation = qs.cancellation;
    this.dto.workingHours = qs.workinghours;
    this.dto.catering = qs.catering;
    this.dto.travelExpenses = qs.travelexpenses;
    this.dto.duration = qs.duration;
    this.dto.dates = qs.dates;
    value = Number.parseFloat(qs.durationdays);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.durationDays = value;
    value = Number.parseFloat(qs.durationhourtarget);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.durationHourTarget = value;
    value = Number.parseFloat(qs.eventnr);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.eventNr = value;
    this.dto.intendedDate = qs.intendeddate;
    this.dto.openDate = qs.opendate;
    this.dto.leader = qs.leader;
    this.dto.meetingPoint = qs.meetingpoint;
    this.dto.notes = qs.notes;
    this.dto.administration = qs.administration;
    this.dto.organisation = qs.organisation;
    this.dto.remarks = qs.remarks;
    value = Number.parseFloat(qs.costsperparticipant);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.costsPerParticipant = value;
    value = Number.parseFloat(qs.participationcosts);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.participationCosts = value;
    this.dto.eventManager = qs.eventmanager;
    this.dto.previousKnowledge = qs.previousknowledge;
    this.dto.requirements = qs.requirements;
    this.dto.schedule = qs.schedule;
    this.dto.targetAudience = qs.targetaudience;
    this.dto.eventGoals = qs.eventgoals;
    this.dto.teachingAid = qs.teachingaid;
    this.dto.teachingMethod = qs.teachingmethod;
    this.dto.eventTeachingMethod = qs.eventteachingmethod;
    this.dto.times = qs.times;
    this.dto.name = qs.name;
    this.dto.abbreviation = qs.abbreviation;
    this.dto.startDate = qs.startdate;
    this.dto.endDate = qs.enddate;
    this.dto.imageUrl = qs.imageurl;
    this.dto.imageThumbUrl = qs.imagethumburl;
    value = Number.parseFloat(qs.price);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.price = value;
    this.dto.registrationStatus = qs.registrationstatus;
    this.dto.registrationIndicator = qs.registrationindicator;
    value = qs.isOnlineRegistrationActive === 'true' ? true : qs.isonlineregistrationactive;
    value = qs.isOnlineRegistrationActive === 'false' ? false : undefined;
    this.dto.isOnlineRegistrationActive = value;
    this.dto.detailUrl = qs.detailurl;
    value = Number.parseFloat(qs.eventtype);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.eventType = value;
    this.dto.registrationFormUrl = qs.registrationformurl;
    this.dto.eventInformationDocumentUrl = qs.eventinformationdocumenturl;
    this.dto.eventDocumentsUrl = qs.eventdocumentsurl;
    this.dto.locationMapUrl = qs.locationmapurl;
  }
}
