import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import WidgetEventsApi from '../generated/api/WidgetEvents';

export default (service: Servicelayer) => ({
  ...WidgetEventsApi(service),

  // Add custom calls here

});
