// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import OnlinePaymentTransactionUpdatedResourceViewModel from '../../../../viewModel/resource/OnlinePaymentTransactionUpdatedResourceViewModel';
import OnlinePaymentTransactionUpdatedResourceDTO from '../../dto/resource/OnlinePaymentTransactionUpdatedResourceDTO';

export default abstract class OnlinePaymentTransactionUpdatedResourceModel extends BaseModel<OnlinePaymentTransactionUpdatedResourceDTO> {
  /**
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.id = qs.id;
  }
}
