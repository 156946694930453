// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import TokenRequestViewModel from '../../viewModel/resource/TokenRequestViewModel';
import AuthTokenResourceViewModel from '../../viewModel/resource/AuthTokenResourceViewModel';
import TokenRefreshRequestViewModel from '../../viewModel/resource/TokenRefreshRequestViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getToken(model: TokenRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-token'] ?? 'core/Auth/GetToken';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const dto = response.data.result;
    return new AuthTokenResourceViewModel().fromDTO(dto);
  },
  /**  */
  async refreshToken(model: TokenRefreshRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-refresh-token'] ?? 'core/Auth/RefreshToken';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const dto = response.data.result;
    return new AuthTokenResourceViewModel().fromDTO(dto);
  },
});
