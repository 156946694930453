// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import Int64LookupResourceViewModel from '../../../../viewModel/resource/Int64LookupResourceViewModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import Int64LookupResourceListResultViewModel from '../../../../viewModel/resource/Int64LookupResourceListResultViewModel';
import Int64LookupResourceListResultDTO from '../../dto/resource/Int64LookupResourceListResultDTO';

export default abstract class Int64LookupResourceListResultModel extends BaseModel<Int64LookupResourceListResultDTO> {
  /**
  */
  get items() { return this.getArrayModels(() => this.dto.items, Int64LookupResourceViewModel)!; }
  set items(value) { this.setArrayModels(() => this.dto.items, value); }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
  }
}
