import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import MembershipsApi from '../generated/api/Memberships';

export default (service: Servicelayer) => ({
  ...MembershipsApi(service),

  // Add custom calls here

});
