// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import WidgetSearchEventRequestViewModel from '../../viewModel/resource/WidgetSearchEventRequestViewModel';
import WidgetEventListResourcePagingResultViewModel from '../../viewModel/resource/WidgetEventListResourcePagingResultViewModel';
import DynamicFormFieldDefinitionResourceListResultViewModel from '../../viewModel/resource/DynamicFormFieldDefinitionResourceListResultViewModel';
import RegisterEventRequestViewModel from '../../viewModel/resource/RegisterEventRequestViewModel';
import WidgetEventResourceViewModel from '../../viewModel/resource/WidgetEventResourceViewModel';
import Int64LookupResourceListResultViewModel from '../../viewModel/resource/Int64LookupResourceListResultViewModel';
import WidgetSearchEventInformationRequestViewModel from '../../viewModel/resource/WidgetSearchEventInformationRequestViewModel';
import WidgetEventInformationResourceViewModel from '../../viewModel/resource/WidgetEventInformationResourceViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getWidgetEvents(model: WidgetSearchEventRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-widget-events'] ?? 'WidgetEvents';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const dto = response.data.result;
    return new WidgetEventListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async getWidgetEventRegistrationQuestions(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-widget-event-registration-questions'] ?? 'WidgetEvents/{id}/RegistrationQuestions';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new DynamicFormFieldDefinitionResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async widgetRegisterForEvent(id: number, model: RegisterEventRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-widget-register-for-event'] ?? 'WidgetEvents/{id}/Register';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async getWidgetEventDetail(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-widget-event-detail'] ?? 'WidgetEvents/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new WidgetEventResourceViewModel().fromDTO(dto);
  },
  /**  */
  async getWidgetCountries(config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-widget-countries'] ?? 'WidgetEvents/countries';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new Int64LookupResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async getWidgetEventInfo(model: WidgetSearchEventInformationRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-widget-event-info'] ?? 'WidgetEvents/EventInformation';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const dto = response.data.result;
    return new WidgetEventInformationResourceViewModel().fromDTO(dto);
  },
  /**  */
  async downloadPdf(model: WidgetSearchEventInformationRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-download-pdf'] ?? 'WidgetEvents/DownloadPdf';
    const response = await service.post<any>(endpointPath, {
      responseType: 'blob',
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return new Blob([result], { type: 'application/octet-stream' });
  },
});
