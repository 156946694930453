// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import OnlinePaymentContactAddressResourceViewModel from '../../../../viewModel/resource/OnlinePaymentContactAddressResourceViewModel';
import OnlinePaymentOrderItemResourceViewModel from '../../../../viewModel/resource/OnlinePaymentOrderItemResourceViewModel';
import OnlinePaymentTransactionStateResourceViewModel from '../../../../viewModel/resource/OnlinePaymentTransactionStateResourceViewModel';
import OnlinePaymentTransactionResourceViewModel from '../../../../viewModel/resource/OnlinePaymentTransactionResourceViewModel';
import OnlinePaymentTransactionResourceDTO from '../../dto/resource/OnlinePaymentTransactionResourceDTO';

export default abstract class OnlinePaymentTransactionResourceModel extends BaseModel<OnlinePaymentTransactionResourceDTO> {
  /**
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get merchantReference() { return this.dto.merchantReference; }
  set merchantReference(value) { this.dto.merchantReference = value; }
  /**
  */
  get currency() { return this.dto.currency; }
  set currency(value) { this.dto.currency = value; }
  /**
  * @type {double}
  */
  get totalPrice() { return this.dto.totalPrice; }
  set totalPrice(value) { this.dto.totalPrice = value; }
  /**
  */
  get language() { return this.dto.language; }
  set language(value) { this.dto.language = value; }
  /**
  */
  get billingAddress() { return this.getObjectModel(() => this.dto.billingAddress, OnlinePaymentContactAddressResourceViewModel)!; }
  set billingAddress(value) { this.setObjectModel(() => this.dto.billingAddress, value); }
  /**
  */
  get shippingAddress() { return this.getObjectModel(() => this.dto.shippingAddress, OnlinePaymentContactAddressResourceViewModel)!; }
  set shippingAddress(value) { this.setObjectModel(() => this.dto.shippingAddress, value); }
  /**
  */
  get orderItems() { return this.getArrayModels(() => this.dto.orderItems, OnlinePaymentOrderItemResourceViewModel)!; }
  set orderItems(value) { this.setArrayModels(() => this.dto.orderItems, value); }
  /**
  * @type {int32}
  */
  get state() { return this.dto.state; }
  set state(value) { this.dto.state = value; }
  /**
  */
  get recurringOnlinePaymentTokenId() { return this.dto.recurringOnlinePaymentTokenId; }
  set recurringOnlinePaymentTokenId(value) { this.dto.recurringOnlinePaymentTokenId = value; }
  /**
  */
  get paymentConnectorId() { return this.dto.paymentConnectorId; }
  set paymentConnectorId(value) { this.dto.paymentConnectorId = value; }
  /**
  * @type {date-time}
  */
  get createdOn() { return this.dto.createdOn; }
  set createdOn(value) { this.dto.createdOn = value; }
  /**
  * @type {date-time}
  */
  get completedOn() { return this.dto.completedOn; }
  set completedOn(value) { this.dto.completedOn = value; }
  /**
  * @type {date-time}
  */
  get failedOn() { return this.dto.failedOn; }
  set failedOn(value) { this.dto.failedOn = value; }
  /**
  * @type {int32}
  */
  get version() { return this.dto.version; }
  set version(value) { this.dto.version = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.id = qs.id;
    this.dto.merchantReference = qs.merchantreference;
    this.dto.currency = qs.currency;
    value = Number.parseFloat(qs.totalprice);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.totalPrice = value;
    this.dto.language = qs.language;
    value = Number.parseFloat(qs.state);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.state = value;
    this.dto.recurringOnlinePaymentTokenId = qs.recurringonlinepaymenttokenid;
    this.dto.paymentConnectorId = qs.paymentconnectorid;
    this.dto.createdOn = qs.createdon;
    this.dto.completedOn = qs.completedon;
    this.dto.failedOn = qs.failedon;
    value = Number.parseFloat(qs.version);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.version = value;
  }
}
