// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetIsUserMemberRequestViewModel from '../../viewModel/request/Memberships/GetIsUserMemberRequestViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getIsUserMember(request: GetIsUserMemberRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-is-user-member'] ?? 'Memberships/isMember';
    const response = await service.get<any>(endpointPath, {
      query: {
        email: requestDTO.email,
        memberNr: requestDTO.memberNr,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as boolean | undefined;
  },
});
