// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ApiPagingRequestViewModel from '../../../../viewModel/resource/ApiPagingRequestViewModel';
import Int32LookupRequestViewModel from '../../../../viewModel/resource/Int32LookupRequestViewModel';
import Int32LookupRequestDTO from '../../dto/resource/Int32LookupRequestDTO';

export default abstract class Int32LookupRequestModel extends BaseModel<Int32LookupRequestDTO> {
  /**
  * @type {int32}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get key() { return this.dto.key; }
  set key(value) { this.dto.key = value; }
  /**
  */
  get searchCriteria() { return this.dto.searchCriteria; }
  set searchCriteria(value) { this.dto.searchCriteria = value; }
  /**
  */
  get paging() { return this.getObjectModel(() => this.dto.paging, ApiPagingRequestViewModel)!; }
  set paging(value) { this.setObjectModel(() => this.dto.paging, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    this.dto.key = qs.key;
    this.dto.searchCriteria = qs.searchcriteria;
  }
}
