// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import OnlinePaymentTransactionProcessingResourceViewModel from '../../../../viewModel/resource/OnlinePaymentTransactionProcessingResourceViewModel';
import OnlinePaymentTransactionProcessingResourceDTO from '../../dto/resource/OnlinePaymentTransactionProcessingResourceDTO';

export default abstract class OnlinePaymentTransactionProcessingResourceModel extends BaseModel<OnlinePaymentTransactionProcessingResourceDTO> {
  /**
  */
  get transactionId() { return this.dto.transactionId; }
  set transactionId(value) { this.dto.transactionId = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.transactionId = qs.transactionid;
  }
}
