// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import UploadDocumentRequestViewModel from '../../viewModel/request/Documents/UploadDocumentRequestViewModel';
import DocumentResourceViewModel from '../../viewModel/resource/DocumentResourceViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getDocumentByExternalId(externalId: string, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-document-by-external-id'] ?? 'core/Documents/{externalId}';
    endpointPath = endpointPath.replace(/\{externalId\}/g, `${externalId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new DocumentResourceViewModel().fromDTO(dto);
  },
  /**  */
  async uploadDocument(request: UploadDocumentRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    const formData = new FormData();
    formData.append('uploadFile', requestDTO.uploadFile as Blob);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-upload-document'] ?? 'core/Documents/Upload';
    const response = await service.post<any>(endpointPath, {
      body: formData,
      headers: {
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as string | undefined;
  },
  /**  */
  async downloadDocument(externalId: string, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-download-document'] ?? 'core/Documents/{externalId}/Download';
    endpointPath = endpointPath.replace(/\{externalId\}/g, `${externalId}`);
    const response = await service.get<any>(endpointPath, {
      responseType: 'blob',
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return new Blob([result], { type: 'application/octet-stream' });
  },
});
