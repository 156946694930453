// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import DynamicFormFieldInputTypeResourceViewModel from '../../../../viewModel/resource/DynamicFormFieldInputTypeResourceViewModel';
import DynamicFormPicklistItemDefinitionResourceViewModel from '../../../../viewModel/resource/DynamicFormPicklistItemDefinitionResourceViewModel';
import DynamicFormFieldDefinitionResourceViewModel from '../../../../viewModel/resource/DynamicFormFieldDefinitionResourceViewModel';
import DynamicFormFieldDefinitionResourceDTO from '../../dto/resource/DynamicFormFieldDefinitionResourceDTO';

export default abstract class DynamicFormFieldDefinitionResourceModel extends BaseModel<DynamicFormFieldDefinitionResourceDTO> {
  /**
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get caption() { return this.dto.caption; }
  set caption(value) { this.dto.caption = value; }
  /**
  * @type {int32}
  */
  get order() { return this.dto.order; }
  set order(value) { this.dto.order = value; }
  /**
  * @type {int32}
  */
  get inputType() { return this.dto.inputType; }
  set inputType(value) { this.dto.inputType = value; }
  /**
  */
  get isRequired() { return this.dto.isRequired; }
  set isRequired(value) { this.dto.isRequired = value; }
  /**
  */
  get isMultiInput() { return this.dto.isMultiInput; }
  set isMultiInput(value) { this.dto.isMultiInput = value; }
  /**
  */
  get isClearable() { return this.dto.isClearable; }
  set isClearable(value) { this.dto.isClearable = value; }
  /**
  */
  get picklistItems() { return this.getArrayModels(() => this.dto.picklistItems, DynamicFormPicklistItemDefinitionResourceViewModel)!; }
  set picklistItems(value) { this.setArrayModels(() => this.dto.picklistItems, value); }
  /**
  */
  get min() { return this.dto.min; }
  set min(value) { this.dto.min = value; }
  /**
  */
  get max() { return this.dto.max; }
  set max(value) { this.dto.max = value; }
  /**
  */
  get defaultValue() { return this.dto.defaultValue; }
  set defaultValue(value) { this.dto.defaultValue = value; }
  /**
  */
  get placeholder() { return this.dto.placeholder; }
  set placeholder(value) { this.dto.placeholder = value; }
  /**
  */
  get description() { return this.dto.description; }
  set description(value) { this.dto.description = value; }
  /**
  */
  get key() { return this.dto.key; }
  set key(value) { this.dto.key = value; }
  /**
  * @type {int32}
  */
  get decimalPlaces() { return this.dto.decimalPlaces; }
  set decimalPlaces(value) { this.dto.decimalPlaces = value; }
  /**
  */
  get items() { return this.getArrayModels(() => this.dto.items, DynamicFormFieldDefinitionResourceViewModel)!; }
  set items(value) { this.setArrayModels(() => this.dto.items, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.id = qs.id;
    this.dto.caption = qs.caption;
    value = Number.parseFloat(qs.order);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.order = value;
    value = Number.parseFloat(qs.inputtype);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.inputType = value;
    value = qs.isRequired === 'true' ? true : qs.isrequired;
    value = qs.isRequired === 'false' ? false : undefined;
    this.dto.isRequired = value;
    value = qs.isMultiInput === 'true' ? true : qs.ismultiinput;
    value = qs.isMultiInput === 'false' ? false : undefined;
    this.dto.isMultiInput = value;
    value = qs.isClearable === 'true' ? true : qs.isclearable;
    value = qs.isClearable === 'false' ? false : undefined;
    this.dto.isClearable = value;
    this.dto.min = qs.min;
    this.dto.max = qs.max;
    this.dto.defaultValue = qs.defaultvalue;
    this.dto.placeholder = qs.placeholder;
    this.dto.description = qs.description;
    this.dto.key = qs.key;
    value = Number.parseFloat(qs.decimalplaces);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.decimalPlaces = value;
  }
}
